

import { GrPhone, GrMailOption, GrMapLocation } from "react-icons/gr";
import React, { useState, useEffect } from 'react';
import { FaCheckDouble, FaUserTie, FaUserShield, FaMedal, FaWarehouse, FaStar, FaTelegramPlane, FaMapMarkedAlt, FaRegStar, FaPhoneAlt, FaArrowRight, FaRegThumbsUp, FaHome, FaBuilding, FaRegBuilding } from "react-icons/fa";
import PreLoader from '../common/PreLoader';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { seoData } from "../../data";



const Contact = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [mySpinner, setMySpinner] = useState(false);
    const [banner, setBanner] = useState('');
    const [bannertitle, setBannertitle] = useState([]);
    const [heroData, setHeroData] = useState([]);
    const [bannerdescription, setBannerdescription] = useState([]);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [embed, setEmbed] = useState('');

    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const [featureImage, setFeatureImage] = useState('');

    const [address, setAddress] = useState('');
    const [data, setData] = useState([]);
    const [name, setName] = useState('');

    const [name1, setName1] = useState('');
    const [phone1, setPhone1] = useState('');
    const [email1, setEmail1] = useState('');
    const [service, setService] = useState('')
    const [message, setMessage] = useState('')
    const [pending, setPending] = useState(false)

    const getData = () => {

        axios.get(`${api_url}/getBanner/4`).then((response) => {
            console.log(response.data.data);
            setBanner(response.data.data[0].banner_image)
            setBannertitle(response.data.data[0].banner_title)
            setBannerdescription(response.data.data[0].banner_description)

            setHeroData(response.data.data)
        }).catch((err) => {
            console.log(err)
        });

        axios.get(`${api_url}/getseo/4`).then((response) => {
            setSeoContent(response.data.data);
            console.log(response.data.data);
        }).catch((err) => {
            console.log(err);
        })


        axios.get(`${api_url}/getFooter`).then((response) => {
            console.log(response.data);
            setEmail(response.data.data[0].enq_email)
            setPhone(response.data.data[0].phone)
            setAddress(response.data.data[0].address)
            setEmbed(response.data.data[0].map_embed)



            setData(response.data.data)
        }).catch((err) => {
            console.log(err);
        });

    };

    useEffect(() => {
        getData();
    }, []);

    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    const enqSuccess = () => toast.success('Enquiry send successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = () => {
        axios.post(`${api_url}/complaint`, {
            name: name,
            email: email1,
            phone: phone1,
            service_id: service,
            message: message
        }, {
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                enqSuccess();
                reset({
                    name: '',
                    email: '',
                    phone: '',
                    service: '',
                    message: '',
                })
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const blockedWords = [
        'Offer',
        'Credit',
        'Loans',
        'Winner',
        'Investment',
        'Pharmacy',
        'Viagra',
        'Cialis',
        'Weight loss',
        'SEO',
        'Web traffic',
        'Click here',
        'Subscribe',
        'Buy now',
        'Order now',
        'Promotion',
        'Marketing',
        'Affiliate',
        'Billion',
        'Million',
        'Cash bonus',
        'proven techniques',
        'google'
    ];

    const validateLetters = (value) => {
        // Remove spaces from the value and count the letters
        const letterCount = value.replace(/\s/g, '').length;
        return letterCount >= 25; // Minimum of 25 letters required
    };

    const validateBlockedWords = (value) => {
        // Check if value contains any blocked words
        const containsBlockedWords = blockedWords.some(word =>
            value.toLowerCase().includes(word.toLowerCase())
        );

        // Return false if contains blocked words, else true
        return !containsBlockedWords;
    };


    return (
        <>
            <Helmet>
                <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle ? seoTitle : seoData.contactUs.title}</title>
                <meta name="description" content={seoDes ? seoDes : seoData.contactUs.description} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle ? seoTitle : seoData.contactUs.title} />
                <meta property="og:description" content={seoDes ? seoDes : seoData.contactUs.description} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle ? seoTitle : seoData.contactUs.title} />
                <meta name="twitter:description" content={seoDes ? seoDes : seoData.contactUs.description} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>

            {mySpinner && <PreLoader />}
            <ToastContainer />


            <section class="page-header">
                <div class="page-header-bg" style={{ backgroundImage: `url(${api_url}/assets/banner/${banner}` }}>
                </div>
                <div class="container">
                    <div class="page-header__inner">
                        <div class="page-header__shape-1 float-bob-y">
                            <img src={`${api_url}/assets/staticimg/page-header-shape-1.png`} alt="static-img" />
                        </div>
                        <h1>{bannertitle}</h1>
                        <ul class="thm-breadcrumb list-unstyled">
                            <li><Link to="/">Home</Link></li>
                            <li><span>/</span></li>
                            <li>{bannertitle}</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section class="contact-page">
                <div class="container">
                    <div class="section-title text-left">
                        <span class="section-title__tagline">get in touch</span>
                        <h2 class="section-title__title">Drop a Message</h2>
                    </div>
                    <div class="row">
                        <div class="col-xl-7 col-lg-7">
                            <div class="contact-page__left">
                                <form id="contactForm" onSubmit={handleSubmit(onSubmit)}>
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div class="contact-page__input-box">
                                                <input type="text" placeholder="Name" name="name"

                                                    {...register('name', {
                                                        required: true,
                                                        onChange: (e) => setName(e.target.value)

                                                    })}
                                                />
                                                {errors.name && errors.name.type && <div className="help-block with-errors text-danger">please enter your name</div>}


                                            </div>
                                        </div>
                                        <div class="col-xl-12">
                                            <div class="contact-page__input-box">
                                                <input type="text" placeholder="Phone" name="Phone"


                                                    value={phone1}

                                                    {...register('phone', {
                                                        required: true,
                                                        onChange: (e) => setPhone1(e.target.value)

                                                    })}
                                                />
                                                {errors.phone && errors.phone.type && <div className="help-block with-errors text-danger">please enter your number</div>}




                                            </div>
                                        </div>
                                        <div class="col-xl-12">
                                            <div class="contact-page__input-box">
                                                <input type="email" placeholder="Your Email" name="email"


                                                    value={email1}

                                                    {...register('email', {
                                                        required: true,
                                                        onChange: (e) => setEmail1(e.target.value)

                                                    })}
                                                />
                                                {errors.email && errors.email.type && <div className="help-block with-errors text-danger">please enter your email</div>}


                                            </div>
                                        </div>

                                    </div>


                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div class="contact-page__input-box text-message-box">
                                                <textarea name="message" placeholder="Message"
                                                    value={message}

                                                    {...register('message', {
                                                        required: 'please enter your message',
                                                        validate: {
                                                            hasEnoughLetters: value => validateLetters(value) || 'Message must contain at least 25 letters (excluding spaces)',
                                                            noBlockedWords: value => validateBlockedWords(value) || `Message contains blocked words: ${blockedWords.filter(word => value.toLowerCase().includes(word.toLowerCase())).join(', ')}`,
                                                        },
                                                        onChange: (e) => setMessage(e.target.value)

                                                    })}
                                                />
                                                {errors.message && errors.message.type && <div className="help-block with-errors text-danger">{errors.message.message}</div>}



                                            </div>
                                            <div class="contact-page__btn-box">
                                                <button type="submit" class="thm-btn contact-page__btn">submit now <span
                                                    class="fas fa-arrow-right"><FaArrowRight /></span></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-5">
                            <div class="contact-page__right">
                                <div class="contact-page__content">
                                    <h3 class="contact-page__title">ANY QUESTIONS?</h3>
                                    <p class="contact-page__text">call or contact Ken’s Power House Electrics today</p>
                                    <ul class="list-unstyled contact-page__content-info">
                                        <li>
                                            <div class="icon"><FaMapMarkedAlt />
                                                <span class="icon-location"></span>
                                            </div>
                                            <div class="content">
                                                <h4>Address :</h4>

                                                <Link to={'/'}>{`${address}`}</Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon"><FaPhoneAlt />
                                                <span class="icon-telephone"></span>
                                            </div>
                                            <div class="content">
                                                <h4>Phone :</h4>

                                                <a href={`tel:${phone}`}>{phone}</a>

                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon"><FaWarehouse />
                                                <span class="icon-email"></span>
                                            </div>
                                            <div class="content">
                                                <h4>Email :</h4>
                                                <a href={`mailto:${email}`}>{email}</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="contact-page-google-map">
                <div class="container">
                    <div class="contact-page-google-map__inner">
                        <iframe
                            src={embed}
                            class="google-map__one" ></iframe>
                    </div>
                </div>
            </section>



        </>

    )
}

export default Contact