
import Form from 'react-bootstrap/Form';
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";
import { Autoplay } from 'swiper/modules';
import { FreeMode, Pagination } from "swiper/modules";
import { GrAchievement, GrAscend, GrMapLocation } from "react-icons/gr";
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import ProductLists from '../ProductLists';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { FaCheckDouble, FaUserTie, FaClock, FaMoneyBillAlt, FaNetworkWired, FaUserShield, FaMedal, FaStar, FaTelegramPlane, FaMapMarkedAlt, FaRegStar, FaPhoneAlt, FaArrowRight, FaRegThumbsUp, FaHome, FaBuilding, FaRegBuilding } from "react-icons/fa";
import { MdOutlineAvTimer, MdElectricBolt } from "react-icons/md";
import HTMLRenderer from 'react-html-renderer';
import Rating from '@mui/material/Rating';


import { fixedContainer, odoMotion, pathCircleVariants, svgVariants, textVariants } from '../Header/Motion';
import { motion, transform } from 'framer-motion';
import Odometer from 'react-odometerjs';
import "swiper/css/pagination";
import Counter from './Count';
import PreLoader from '../common/PreLoader';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import AnimatedOdometer from '../common/AnimatedOdometer';
import { seoData } from '../../data';


const Home = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [product, setProduct] = useState('');
    const [allProducts, setAllProducts] = useState([]);
    const [contactInfo, setContactInfo] = useState([]);
    const [mySpinner, setMySpinner] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('message');
    const [phone, setPhone] = useState('');

    const [blogs, setBlogs] = useState([]);
    const [about, setAbout] = useState([]);
    const [why, setWhy] = useState([]);
    const [testimonial, setTestimonial] = useState([]);
    const [service, setService] = useState([]);

    const [phone1, setPhone1] = useState('');
    const [email1, setEmail1] = useState('');


    const [heroData, setHeroData] = useState([]);
    const [bannerdescription, setBannerdescription] = useState([]);
    const [dataPerPage, setDataPerPage] = useState(6);
    const [pending, setPending] = useState(false)
    const [banner, setBanner] = useState('');
    const [bannertitle, setBannertitle] = useState([]);

    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const [featureImage, setFeatureImage] = useState('');

    const getAllData = () => {

        axios.get(`${api_url}/getBanner/1`).then((response) => {
            console.log(response.data.data);
            setBanner(response.data.data[0].banner_image)
            setBannertitle(response.data.data[0].banner_title)
            setBannerdescription(response.data.data[0].banner_description)

            setHeroData(response.data.data)
        }).catch((err) => {
            console.log(err)
        });

        axios.get(`${api_url}/getseo/1`).then((response) => {
            setSeoContent(response.data.data);
            console.log(response.data.data);
        }).catch((err) => {
            console.log(err);
        })

        axios.get(`${api_url}/getcardBlog`).then((response) => {
            console.log(response.data.data)
            setBlogs(response.data.data);
        }).catch((err) => {
            console.log(err)
        });

        axios.get(`${api_url}/getsectionaboutUs`).then((response) => {
            console.log(response.data.data)
            setAbout(response.data.data);
        }).catch((err) => {
            console.log(err)
        });

        axios.get(`${api_url}/getwhyChoose`).then((response) => {
            console.log(response.data.data)
            setWhy(response.data.data);
        }).catch((err) => {
            console.log(err)
        });


        axios.get(`${api_url}/getcardServices`).then((response) => {
            console.log(response.data.data)
            setService(response.data.data);
            setAllProducts(response.data.data);
        }).catch((err) => {
            console.log(err)
        });




        axios.get(`${api_url}/getTestimonial`).then((response) => {
            console.log(response.data.data)
            setTestimonial(response.data.data);
            setMySpinner(false)

        }).catch((err) => {
            console.log(err)
        });








    }


    useEffect(() => {
        getAllData();
        setMySpinner(true);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const successEnquiry = () => toast.info('Enquiry send Successfully', {
        position: "top-center"

    });


    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);



    const [odometerValue, setOdometerValue] = useState(450);
    const [odometerValue1, setOdometerValue1] = useState(120);
    const [odometerValue2, setOdometerValue2] = useState(700);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setOdometerValue(450);
    //         setOdometerValue1(120);
    //         setOdometerValue2(700);
    //     }, 1000);
    // }, []);

    const { register, reset, handleSubmit, watch, formState: { errors } } = useForm();

    const onSubmit = () => {
        // setMySpinner(true);
        axios.post(`${api_url}/complaint`, {
            name: name,
            email: email1,
            phone: phone1,
            service_id: product,
            message: message
        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                successEnquiry();
                // setMySpinner(false);
                setName('');
                setEmail('');
                setMessage('');
                setPhone('');
                setProduct('');
                reset({
                    name: '',
                    email: '',
                    phone: '',
                    product: '',
                })

            }
        }).catch((err) => {
            console.log(err);
            // setMySpinner(false);
        })



    }






    return (
        <>

            <Helmet>
                <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle ? seoTitle : seoData.home.title}</title>
                <meta name="description" content={seoDes ? seoDes : seoData.home.description} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle ? seoTitle : seoData.home.title} />
                <meta property="og:description" content={seoDes ? seoDes : seoData.home.description} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle ? seoTitle : seoData.home.title} />
                <meta name="twitter:description" content={seoDes ? seoDes : seoData.home.description} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>

            {mySpinner && <PreLoader />}
            <ToastContainer />


            <div class="page-wrapper">
                <section class="main-slider">

                    <div class="main-slider__bg" style={{ backgroundImage: `url(${api_url}/assets/banner/${banner}` }}>
                    </div>
                    <div class="main-slider__big-text">Electricity</div>
                    <div class="main-slider__wrap">
                        <div class="container">
                            <div class="main-slider__inner">
                                <h1 class="main-slider__title">{bannertitle}</h1>
                                <p class="main-slider__text"></p>
                                <a href="tel:0411632264" class="main-slider__btn thm-btn">Book Now</a>


                                <div class="main-slider__three-facts">
                                    <ul class="list-unstyled main-slider__three-facts-list">
                                        <li>
                                            <div class="icon" ><FaMedal />
                                                <span class="icon-electrician"></span>
                                            </div>
                                            <h5>Expert Electrician</h5>
                                        </li>
                                        <li>
                                            <div class="icon"><MdOutlineAvTimer />
                                                <span class="icon-plug"></span>
                                            </div>
                                            <h5>24/Hour Emergency Service</h5>
                                        </li>
                                        <li>
                                            <div class="icon"><FaMoneyBillAlt />
                                                <span class="icon-light-bulb"></span>
                                            </div>
                                            <h5>Free Consultations</h5>
                                        </li>
                                        <li>
                                            <div class="icon"><FaMapMarkedAlt />
                                                <span class="icon-light-bulb"></span>
                                            </div>
                                            <h5>Local Electrician</h5>
                                        </li>
                                        <li>
                                            <div class="icon"><MdElectricBolt />
                                                <span class="icon-light-bulb"></span>
                                            </div>
                                            <h5>Old Fashion Services</h5>
                                        </li>

                                    </ul>
                                </div>
                                <motion.div class="main-slider__shape-1 float-bob-y"
                                    variants={textVariants('left', 0.2)}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{ once: true, amount: 0.1 }}
                                    transition={{ ease: "linear", duration: 2, repeat: Infinity }}>
                                    <img src={`${api_url}/assets/staticimg/main-slider-shape-1.png`} alt="static-img" />
                                </motion.div>
                                <div class="main-slider__shape-2 float-bob-x">
                                    <img src={`${api_url}/assets/staticimg/main-slider-shape-2.png`} alt="static-img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--Main Slider End--> */}

                {/* <!--Appointment One Start--> */}
                <section class="appointment-one">
                    <div class="container">
                        <div class="appointment-one__inner">
                            <form id="contactForm" onSubmit={handleSubmit(onSubmit)}>
                                <ul class="list-unstyled appointment-one__list clearfix">
                                    <li>
                                        <div class="appointment-one__input">
                                            <input type="text" placeholder="Name" name="name"

                                                {...register('name', {
                                                    required: true,
                                                    onChange: (e) => setName(e.target.value)

                                                })}
                                            />
                                            {errors.name && errors.name.type && <div className="help-block with-errors text-danger">please enter name</div>}

                                        </div>
                                    </li>
                                    <li>
                                        <div class="appointment-one__input">
                                            <input type="email" placeholder="Your Email" name="email"


                                                value={email1}

                                                {...register('email', {
                                                    required: true,
                                                    onChange: (e) => setEmail1(e.target.value)

                                                })}
                                            />
                                            {errors.email && errors.email.type && <div className="help-block with-errors text-danger">please enter email</div>}


                                        </div>
                                    </li>
                                    <li>
                                        <div class="appointment-one__input">
                                            <input type="text" placeholder="Phone" name="Phone"


                                                value={phone1}

                                                {...register('phone', {
                                                    required: true,
                                                    onChange: (e) => setPhone1(e.target.value)

                                                })}
                                            />
                                            {errors.phone && errors.phone.type && <div className="help-block with-errors text-danger">please enter number</div>}

                                        </div>
                                    </li>
                                    <li>
                                        <Form.Group className=" col-sm-12 mb-3">
                                            <Form.Select
                                                value={product}
                                                {...register("product", {
                                                    onChange: (e) => setProduct(e.target.value),
                                                    required: 'Please select service',
                                                    pattern: {
                                                        message: "Please select service"
                                                    }
                                                })}

                                            >
                                                <option value="">Choose Service</option>
                                                {allProducts?.map((product) => {
                                                    return (

                                                        <option key={product.id} value={product.name}>{product.name}</option>


                                                        // <ProductLists product={product.products} category={product.name} />
                                                    )
                                                })}
                                            </Form.Select>

                                            {errors.product && <span className='help-block with-errors text-danger'>{errors.product.message}</span>}

                                        </Form.Group>

                                    </li>
                                    <li>
                                        <div class="appointment-one__input">
                                            <button type="submit" class="thm-btn appointment-one__btn">Schedule an Appointment Now!</button>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </section>

                <section class="about-one">
                    <div class="about-one__img-one wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms">
                        <img src={`${api_url}/assets/cms/${about[0]?.image}`} alt="about" class="float-bob-y" />
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-7 col-lg-9">
                                <div class="about-one__left">
                                    <div class="section-title text-left">
                                        <span class="section-title__tagline">about company</span>
                                        <h2 class="section-title__title">{about[0]?.title}</h2>
                                    </div>
                                    <HTMLRenderer html={about[0]?.short_description}
                                    />

                                    <div class="about-one__points-box">
                                        <ul class="about-one__points-list list-unstyled">
                                            <li>
                                                <div class="about-one__points-icon"><FaCheckDouble />
                                                    <span class="icon-comment"></span>
                                                </div>
                                                <div class="about-one__points-text">
                                                    <p>100% Satisfaction</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="about-one__points-icon"><FaCheckDouble />
                                                    <span class="icon-comment"></span>
                                                </div>
                                                <div class="about-one__points-text">
                                                    <p>Flexible and Cost-Effective</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul class="about-one__points-list list-unstyled">
                                            <li>
                                                <div class="about-one__points-icon"><FaCheckDouble />
                                                    <span class="icon-comment"></span>
                                                </div>
                                                <div class="about-one__points-text">
                                                    <p>Annual Pass Programs</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="about-one__points-icon"><FaCheckDouble />
                                                    <span class="icon-comment"></span>
                                                </div>
                                                <div class="about-one__points-text">
                                                    <p>Trained Employees</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="about-one__bottom">
                                        <div class="about-one__call-box">
                                            <div class="icon">
                                                <span class="icon-telephone"></span><FaPhoneAlt />
                                            </div>
                                            <div class="content">
                                                <h3 class="about-one__title">Call for Service</h3>
                                                <p class="about-one__number"><a href="tel:0411632264">0411 632 264</a></p>
                                            </div>
                                        </div>
                                        <div class="about-one__years-box">
                                            <h3 class="about-one__years">18</h3>
                                            <p class="about-one__years-experience">Years of
                                                Experience</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--About one End--> */}

                {/* <!--Services One Start--> */}
                <section class="services-one">
                    <div class="services-one__top">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-6 col-lg-6">
                                    <div class="services-one__left">
                                        <div class="section-title text-left">
                                            <span class="section-title__tagline">Service Provide</span>
                                            <h2 class="section-title__title">We Serve All Sectors</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6">
                                    <div class="services-one__right">
                                        <p class="services-one__text">Kens Power House Electrics offers a wide range of electrical services to Melbourne residents and commercial establishments. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="services-one__bottom">
                        <div class="services-one__carousel owl-carousel owl-theme thm-owl__carousel">
                            <Swiper
                                slidesPerView={3}
                                spaceBetween={10}
                                freeMode={true}

                                className="mySwiper"
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 10,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 10,
                                    },
                                }}
                            >

                                {service?.map((data) => {
                                    return (
                                        <SwiperSlide>
                                            <div class="item">
                                                <div class="services-one__single">
                                                    <div class="services-one__img-box">

                                                        <Link to={`/service/${data.name.replace(/\s/g, '').toLowerCase()}/${data.id}`}>
                                                            <div class="services-one__img">

                                                                <img src={`${api_url}/assets/service/${data.image}`} alt="service" />
                                                            </div>
                                                        </Link>
                                                        <div class="services-one__content">
                                                            <h3 class="services-one__title"><Link to={`/service/${data.name.replace(/\s/g, '').toLowerCase()}/${data.id}`}>{data.name}<i class="icon-right-arrow"><FaArrowRight /></i></Link></h3>
                                                        </div>

                                                    </div>
                                                    <div class="services-one__shape-2"></div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}

                            </Swiper>





                        </div>
                    </div>
                </section>

                {/* <div> <Counter/></div> */}

                {/* <!--Counter One Start--> */}
                <section class="counter-one">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4">
                                <div class="counter-one__left">
                                    <div class="counter-one__year-box">
                                        <p class="counter-one__year"><span class="count percent" data-count="100">
                                            18
                                        </span>Year</p>
                                        <h3 class="counter-one__year-title">Leading The Way In <span> Projects</span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8 col-lg-8">
                                <div class="counter-one__right">
                                    <ul class="counter-one__box list-unstyled">
                                        <li class="counter-one__single">
                                            <div class="counter-one__icon">
                                                <span class="icon-home"><FaHome /></span>
                                            </div>
                                            <div class="counter-one__content-box">
                                                <h3

                                                    class="odometer" data-count="450">
                                                    <AnimatedOdometer value={odometerValue} />
                                                    {/* <Odometer value={odometerValue} /> */}
                                                    {/* <Odometer value={odometerValue} animation='count' format='d' /> */}
                                                </h3><span
                                                    class="counter-one__letter">+</span>
                                                <p class="counter-one__text">Residential
                                                    <br /> Projects</p>
                                            </div>
                                        </li>
                                        <li class="counter-one__single">
                                            <div class="counter-one__icon">
                                                <span class="icon-office-building"><FaRegBuilding /></span>
                                            </div>
                                            <div class="counter-one__content-box">
                                                <h3 class="odometer" data-count="120">
                                                    <AnimatedOdometer value={odometerValue1} />
                                                </h3><span
                                                    class="counter-one__letter">+</span>
                                                <p class="counter-one__text">Commercial
                                                    <br /> Projects</p>
                                            </div>
                                        </li>
                                        <li class="counter-one__single">
                                            <div class="counter-one__icon">
                                                <span class="icon-satisfaction"><FaRegThumbsUp /></span>
                                            </div>
                                            <div class="counter-one__content-box">
                                                <h3 class="odometer" data-count="700">
                                                    <AnimatedOdometer value={odometerValue2} />
                                                </h3><span
                                                    class="counter-one__letter">+</span>
                                                <p class="counter-one__text">Customer
                                                    <br /> Satisfaction</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--Counter One End--> */}

                {/* <!--Why Choose One Start--> */}
                <section class="why-choose-one">
                    <div class="container">
                        <div class="why-choose-one__top">
                            <div class="row">
                                <div class="col-xl-6 col-lg-6">
                                    <div class="why-choose-one__left">
                                        <div class="why-choose-one__img-box">
                                            <div class="why-choose-one__img">

                                                <img src={`${api_url}/assets/cms/${why[0]?.image}`} alt="why-choose" />

                                            </div>
                                            <div class="why-choose-one__count-box">
                                                <div class="icon">
                                                    <span class="icon-reviews"><FaRegStar /></span>
                                                </div>
                                                <div class="why-choose-one__content-box">
                                                    <h3 class="odometer" data-count="2700">1000 +</h3>
                                                    <p class="why-choose-one__count-text">Satisfied
                                                        <br />Customers</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6">
                                    <div class="why-choose-one__right">
                                        <div class="section-title text-left">
                                            <span class="section-title__tagline">Our Professionals</span>
                                            <h2 class="section-title__title">{why[0]?.title}</h2>
                                        </div>
                                        <HTMLRenderer html={why[0]?.description}
                                        />

                                        <ul class="why-choose-one__points list-unstyled">
                                            <li>
                                                <div class="icon"><FaArrowRight />
                                                    <span class="fa fa-arrow-right"></span>
                                                </div>
                                                <div class="text">
                                                    <p>24/7 Emergency Services</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="icon"><FaArrowRight />
                                                    <span class="fa fa-arrow-right"></span>
                                                </div>
                                                <div class="text">
                                                    <p>Free Estimates</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="icon"><FaArrowRight />
                                                    <span class="fa fa-arrow-right"></span>
                                                </div>
                                                <div class="text">
                                                    <p>Low Price Guarantee</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="icon"><FaArrowRight />
                                                    <span class="fa fa-arrow-right"></span>
                                                </div>
                                                <div class="text">
                                                    <p>Licensed & Insured Experts</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="icon"><FaArrowRight />
                                                    <span class="fa fa-arrow-right"></span>
                                                </div>
                                                <div class="text">
                                                    <p>Fast & Reliable Response Times</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="why-choose-one__bottom">
                            <div class="row">
                                {/* <!--Why Choose One Single Start--> */}
                                <div class="col-xl-4 col-lg-4">
                                    <div class="why-choose-one__single">
                                        <div class="icon">
                                            <span class="icon-certificate"><FaMedal /></span>
                                        </div>
                                        <div class="why-choose-one__content">
                                            <h3 class="why-choose-one__title">Awarded Company</h3>
                                            <p class="why-choose-one__text-2">There are many variations passages <br /> of Lorem
                                                Ipsum
                                                available, but the majority have suffered alter.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--Why Choose One Single End--> */}
                                {/* <!--Why Choose One Single Start--> */}
                                <div class="col-xl-4 col-lg-4">
                                    <div class="why-choose-one__single">
                                        <div class="icon">
                                            <span class="icon-technician"><FaUserShield /></span>
                                        </div>
                                        <div class="why-choose-one__content">
                                            <h3 class="why-choose-one__title">Trained Workers</h3>
                                            <p class="why-choose-one__text-2">There are many variations passages <br /> of Lorem
                                                Ipsum
                                                available, but the majority have suffered alter.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--Why Choose One Single End--> */}
                                {/* <!--Why Choose One Single Start--> */}
                                <div class="col-xl-4 col-lg-4">
                                    <div class="why-choose-one__single">
                                        <div class="icon">
                                            <span class="icon-flag"><FaUserTie /></span>
                                        </div>
                                        <div class="why-choose-one__content">
                                            <h3 class="why-choose-one__title">Industry Leader</h3>
                                            <p class="why-choose-one__text-2">There are many variations passages <br /> of Lorem
                                                Ipsum
                                                available, but the majority have suffered alter.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--Why Choose One Single End--> */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--Why Choose One End--> */}

                {/* <!--Testimonial One Start--> */}




                <section class="testimonial-one">
                    <div class="testimonial-one__shape-1 float-bob-x">
                        <img src={`${api_url}/assets/staticimg/testimonials-one-shape-1.png`} alt="static-img" />
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4">
                                <div class="testimonial-one__left">
                                    <div class="section-title text-left">
                                        <span class="section-title__tagline">testimonials</span>
                                        <h2 class="section-title__title">What They Say</h2>
                                    </div>

                                    <HTMLRenderer html={testimonial[0]?.content} />

                                </div>


                            </div>
                            <div class="col-xl-8 col-lg-8">
                                <div class="testimonial-one__right">
                                    <div class="testimonial-one__carousel owl-carousel owl-theme thm-owl__carousel"
                                        data-owl-options='{
                                "loop": true,
                                "autoplay": false,
                                "margin": 30,
                                "nav": true,
                                "dots": false,
                                "smartSpeed": 500,
                                "autoplayTimeout": 10000,
                                "navText": ["<span class=\"far fa-arrow-left\"></span>","<span class=\"far fa-arrow-right\"></span>"],
                                "responsive": {
                                    "0": {
                                        "items": 1
                                    },
                                    "768": {
                                        "items": 1
                                    },
                                    "992": {
                                        "items": 1
                                    },
                                    "1200": {
                                        "items": 1
                                    }
                                }
                            }'>
                                        {/* <!--Testimonial One Single Start--> */}
                                        <Swiper
                                            slidesPerView={1}
                                            spaceBetween={20}
                                            freeMode={true}
                                            autoplay={{
                                                delay: 7000,
                                                disableOnInteraction: false,
                                            }}
                                            modules={[Autoplay]}
                                            loop={true}

                                            className="mySwiper"
                                        >
                                            {testimonial?.map((data) => {
                                                return (
                                                    <SwiperSlide>

                                                        <div class="item">





                                                            <div class="testimonial-one__single">
                                                                <div class="testimonial-one__inner">
                                                                    <div class="testimonial-one__client-img">
                                                                        <img src={`${api_url}/assets/staticimg/testimonial-1-1.jpg`} alt="static-img" />
                                                                    </div>
                                                                    <div class="testimonial-one__client-info">
                                                                        <p class="testimonial-one__client-text">{data.description}</p>
                                                                        <Rating name="half-rating-read estimonial-one__client-rating" defaultValue={data.stars} precision={0.5} readOnly />

                                                                    </div>
                                                                </div>
                                                                <div class="testimonial-one__client-content">
                                                                    <h3>{data.name}</h3>
                                                                </div>
                                                            </div>


                                                        </div>




                                                    </SwiperSlide>
                                                )
                                            })}
                                        </Swiper>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--Testimonial One End--> */}


                {/* <section class="blog-one pb-0 text-center">
                    <div class="container">
                        <div class="section-title text-center">
                            <span class="section-title__tagline">our customers</span>
                            <h2 class="section-title__title">CUSTOMER SATISFACTION</h2>
                        </div>
                        <p>At Kens Power House Electrics, we are highly considerate to our customers’ peace of mind. We strive extremely hard to meet and exceed our customers’ expectations. Our fully trained and qualified staff get down to business quickly and achieve the required outcome every time.</p><br></br>
                        <p>Our decades of experience in the industry enable us to identify the problem quickly and determine the appropriate strategy to solve the issue promptly. If you are looking for an electrical contractor in Doncaster, Knoxfield or even an electrician south Melbourne way,  Ken’s Power House Electrics is the call you should make now on 1300 308 794</p><br></br>
                        <p>Ken and his team have been looking after Victoria Carpets electrical maintenance for more than 18 years. His roll has been to do both small and large installation work covering major switchboard upgrades and substation upgrades for the installation of carpet making equipment working with both commissioning engineers and our own internal project team. Ken also covers off the repair and maintenance of plant and equipment involving VSD’s (variable speed drives), light safety curtains, data systems, controls, and electrical circuits etc. Both he and his team are involved with safety audits and subsequent modifications to machines as required to ensure compatibility and compliance. His company is also involved with power audits and lighting upgrades. Ken and his team are technically proficient in maintaining our plant and equipment and strive to keep down time to an absolute minimum.
                            Thanks Malcolm.</p>
                    </div>
                </section> */}


                {/* <!--Blog One Start--> */}
                <section class="blog-one">
                    <div class="container">
                        <div class="section-title text-center">
                            <span class="section-title__tagline">our blogs</span>
                            <h2 class="section-title__title">Recent News & Articles</h2>
                        </div>
                        <div class="row">

                            {blogs?.map((data) => {
                                return (

                                    <div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                                        <div class="blog-one__single">
                                            <div class="blog-one__img-box">

                                                <Link to={`/blogdetails/${data.title.replace(/\s/g, '').toLowerCase()}/${data.id}`}>

                                                    <div class="blog-one__img">


                                                        <img src={`${api_url}/assets/blog/${data.image}`} alt="blog" />


                                                    </div>
                                                </Link>

                                            </div>
                                            <div class="blog-one__content-box">
                                                <div class="blog-one__date">
                                                    <p><span>Admin</span>{dateFormat(`${data.created_at}`, "fullDate")}</p>
                                                </div>
                                                <h3 class="blog-page__title"><Link to={`/blogdetails/${data.title.replace(/\s/g, '').toLowerCase()}/${data.id}`}>{data.title}</Link></h3>
                                            </div>
                                        </div>
                                    </div>


                                )
                            })}



                        </div>
                    </div>
                </section>
                {/* <!--Blog One End--> */}




            </div>
            {/* <!-- /.page-wrapper --> */}

        </>
    )
}

export default Home
