
import { GrAchievement, GrAscend, GrMapLocation } from "react-icons/gr";
import React, { useState, useEffect } from 'react';
import { FaCheckDouble, FaUserTie, FaUserShield, FaMedal, FaStar, FaTelegramPlane, FaMapMarkedAlt, FaRegStar, FaPhoneAlt, FaArrowRight, FaRegThumbsUp, FaHome, FaBuilding, FaRegBuilding } from "react-icons/fa";
import PreLoader from '../common/PreLoader';
import axios from 'axios'
import { Link, useParams } from 'react-router-dom';
import HTMLRenderer from 'react-html-renderer';
import { useForm } from "react-hook-form";
import Form from 'react-bootstrap/Form';
import ProductLists from '../ProductLists';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { seoData } from "../../data";

const Service = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const { id } = useParams();

    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const [featureImage, setFeatureImage] = useState('');

    const [service, setService] = useState([]);
    const [servicelist, setServicelist] = useState([]);





    const [pending, setPending] = useState(false)
    const [allProducts, setAllProducts] = useState([]);
    const [name, setName] = useState('');

    const [name1, setName1] = useState('');
    const [phone1, setPhone1] = useState('');
    const [email1, setEmail1] = useState('');
    const [message, setMessage] = useState('message')


    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);


    const enqSuccess = () => toast.success('Enquiry send successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = () => {
        axios.post(`${api_url}/complaint`, {
            name: name,
            email: email1,
            phone: phone1,
            service_id: productId,
            message: message
        }, {
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                enqSuccess();
                reset({
                    name: '',
                    email: '',
                    phone: '',
                    service_id: '',
                    message: '',
                })
            }
        }).catch((err) => {
            console.log(err);
        })
    }




    const getData = () => {



        axios.get(`${api_url}/getmenuService`).then((response) => {
            console.log(response.data);
            // setLogo(response.data.data[0].logo)
            setAllProducts(response.data.data)
        }).catch((err) => {
            console.log(err);
        })


        axios.get(`${api_url}/getsingleService/${id}`).then((response) => {
            console.log(response.data.data)
            setSeoContent(response.data.data);
            setService(response.data.data);
            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });

        axios.get(`${api_url}/getServicelist/${id}`).then((response) => {
            console.log(response.data.data)
            setServicelist(response.data.data);
            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });



    };

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();
    }, [id])




    const [mySpinner, setMySpinner] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    let productId = '';
    const selectedProduct = service?.map((product) => {

        productId = product.name;

        return (
            <option key={product.id} value={product.id} selected>{product.name}</option>
        )
    })

    let defaultSeoData = seoData.services.filter(item => item.id == id)

    return (
        <>
            <Helmet>
                <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle ? seoTitle : defaultSeoData[0]?.title}</title>
                <meta name="description" content={seoDes ? seoDes : defaultSeoData[0]?.description} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle ? seoTitle : defaultSeoData[0]?.title} />
                <meta property="og:description" content={seoDes ? seoDes : defaultSeoData[0]?.description} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle ? seoTitle : defaultSeoData[0]?.title} />
                <meta name="twitter:description" content={seoDes ? seoDes : defaultSeoData[0]?.description} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>
            {mySpinner && <PreLoader />}
            <ToastContainer />


            <section class="page-header">
                <div class="page-header-bg" style={{ backgroundImage: `url(${api_url}/assets/service/${service[0]?.banner_image}` }}>
                </div>
                <div class="container">
                    <div class="page-header__inner">
                        <div class="page-header__shape-1 float-bob-y">
                            <img src={`${api_url}/assets/staticimg/page-header-shape-1.png`} alt="static-img" />
                        </div>
                        <h1>{service[0]?.banner_title}</h1>
                        <ul class="thm-breadcrumb list-unstyled">
                            <li><Link to="/">Home</Link></li>
                            <li><span>/</span></li>
                            <li>{service[0]?.banner_title}</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section class="electrical-panels">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6">
                            <div class="electrical-panels__left">
                                <div class="electrical-panels__img-box">
                                    <div class="electrical-panels__img-one">
                                        <img src={`${api_url}/assets/service/${service[0]?.image}`} alt="service" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="electrical-panels__right">
                                <div class="section-title text-left">
                                    <span class="section-title__tagline">service details</span>
                                    <h2 class="section-title__title">{service[0]?.title}</h2>
                                </div>
                                <HTMLRenderer html={service[0]?.short_description} />
                            </div>
                        </div>
                    </div>
                </div>

                <section class="appointment-one">
                    <div class="container">
                        <div class="appointment-one__inner">
                            <form id="contactForm" onSubmit={handleSubmit(onSubmit)}>

                                <ul class="list-unstyled appointment-one__list clearfix">
                                    <li>
                                        <div class="appointment-one__input">
                                            <input type="text" placeholder="Name" name="name"

                                                {...register('name', {
                                                    required: true,
                                                    onChange: (e) => setName(e.target.value)

                                                })}
                                            />
                                            {errors.name && errors.name.type && <div className="help-block with-errors text-danger">please enter name</div>}

                                        </div>
                                    </li>
                                    <li>
                                        <div class="appointment-one__input">
                                            <input type="email" placeholder="Your Email" name="email"


                                                value={email1}

                                                {...register('email', {
                                                    required: true,
                                                    onChange: (e) => setEmail1(e.target.value)

                                                })}
                                            />
                                            {errors.email && errors.email.type && <div className="help-block with-errors text-danger">please enter email</div>}


                                        </div>
                                    </li>
                                    <li>
                                        <div class="appointment-one__input">
                                            <input type="text" placeholder="Phone" name="Phone"


                                                value={phone1}

                                                {...register('phone', {
                                                    required: true,
                                                    onChange: (e) => setPhone1(e.target.value)

                                                })}
                                            />
                                            {errors.phone && errors.phone.type && <div className="help-block with-errors text-danger">please enter number</div>}

                                        </div>
                                    </li>
                                    <li>
                                        <Form.Group className="appointment-one__input">

                                            <Form.Select disabled>

                                                {selectedProduct}
                                            </Form.Select>
                                        </Form.Group>
                                    </li>
                                    <li>
                                        <div class="appointment-one__input">
                                            <button type="submit" class="thm-btn appointment-one__btn">Schedule an Appointment Now!</button>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </section>
                <section class="secondsection">
                    <div class="container">
                        <HTMLRenderer html={service[0]?.description} />

                    </div>
                </section>
                <div class="container">
                    <div className='mt-5'>
                        <h2 className='fw-bold'>{service[0]?.list_title}</h2>
                        <p>{service[0]?.list_description}</p>
                        {servicelist?.length != 0 ? <div className='row'>
                            <div className='col-xl-12'>
                                <ul class="list-unstyled electrical-panels__points">

                                    {servicelist?.map((data) => {
                                        return (

                                            <li>
                                                <div class="icon"><FaCheckDouble />
                                                    <span class="icon-comment"></span>
                                                </div>
                                                <div class="text">
                                                    <p>{data.list}</p>
                                                </div>
                                            </li>

                                        )
                                    })}

                                </ul>
                            </div>

                        </div> : null}
                    </div>

                </div>
            </section>






        </>

    )
}

export default Service