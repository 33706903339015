import React from 'react'
import { Link } from 'react-router-dom';

const NotFound = () => {

  const api_url = process.env.REACT_APP_API_URL;

  return (
    <>
      <section class="page-header">
        <div class="page-header-bg" style={{ backgroundImage: `url(${api_url}/assets/banner/${''}` }}>
        </div>
        <div class="container">
          <div class="page-header__inner">
            <div class="page-header__shape-1 float-bob-y">
              <img src={`${api_url}/assets/staticimg/page-header-shape-1.png`} alt="static-img" />
            </div>
            <div className='text-center'>
              <h1>
                <span className='not-found' style={{ fontSize: '124px', lineHeight: '1' }}>404</span>
              </h1>
              <h1>Page Not Found</h1>
              <Link to="/" class="main-slider__btn thm-btn mt-4">Go to Home</Link>
            </div>

            {/* <ul class="thm-breadcrumb list-unstyled">
              <li><Link to="/">Home</Link></li>
              <li><span>/</span></li>
              <li>{bannertitle}</li>
            </ul> */}
          </div>
        </div>
      </section>
    </>
  )
}

export default NotFound