import React, { useLayoutEffect, useState, useEffect, useRef, useCallback } from 'react';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaGooglePlusG } from 'react-icons/fa'
import { Link, NavLink } from 'react-router-dom';
import { FaPhoneAlt } from 'react-icons/fa'
import axios from 'axios';
import { MdEmail } from 'react-icons/md';
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { AiFillMessage } from 'react-icons/ai'
import ProductLists from '../ProductLists';
import { ToastContainer, toast } from 'react-toastify';

const Footer = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [message, setMessage] = useState('message');

    const [data, setData] = useState([]);
    const [product, setProduct] = useState('');
    const [allProducts, setAllProducts] = useState([]);
    const [footerlogo, setfooterLogo] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [fb, setFb] = useState('');
    const [insta, setInsta] = useState('');
    const [twtr, setTwtr] = useState('');
    const [service, setService] = useState([]);


    const [show, setShow] = useState(false);
    const [name, setName] = useState('');
    const [phone1, setPhone1] = useState('');
    const [email1, setEmail1] = useState('');

    const handleClose = () => {
        setShow(false);
        reset({
            name: "",
            phone: "",
            email: "",
            message: "",

        });
    };
    const handleShow = () => setShow(true);


    const { register, handleSubmit, reset, formState: { errors } } = useForm();



    const [msg, setMsg] = useState('');

    const [therapiesMenu, setTherapiesMenu] = useState([]);
    const [mySpinner, setMySpinner] = useState(false);
    const [click, setClick] = React.useState(false);
    const handleClick = () => setClick(!click);
    const Close = () => setClick(false);


    const getData = () => {
        axios.get(`${api_url}/getFooter`).then((response) => {
            console.log(response.data);
            setfooterLogo(response.data.data[0].footer_logo)
            setEmail(response.data.data[0].enq_email)
            setPhone(response.data.data[0].phone)
            setAddress(response.data.data[0].address)
            setFb(response.data.data[0].fb_url)
            setInsta(response.data.data[0].insta_url)
            setTwtr(response.data.data[0].twtr_url)


            setData(response.data.data)
        }).catch((err) => {
            console.log(err);
        });

        axios.get(`${api_url}/getcardServices`).then((response) => {
            console.log(response.data.data)
            setService(response.data.data);
            setAllProducts(response.data.data);
        }).catch((err) => {
            console.log(err)
        });


        axios.get(`${api_url}/getmenuService`).then((response) => {
           
            // setLogo(response.data.data[0].logo)
            setTherapiesMenu(response.data.data)
        }).catch((err) => {
            console.log(err);
        })


    }


    const successEnquiry = () => toast.info('Enquiry send Successfully', {
        position: "top-center"

    });



    useEffect(() => {
        getData();
        setMySpinner(true);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    const onSubmit = () => {
        setMySpinner(true);
        axios.post(`${api_url}/complaint`, {
            name: name,
            email: email1,
            phone: phone1,
            service_id: product,
            message: message
        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                successEnquiry();
                setMySpinner(false);
                setName('');
                setEmail('');
                setMessage('');
                setPhone('');
                setProduct('');
                handleClose();

                reset({
                    name: '',
                    email: '',
                    phone: '',
                    product: '',
                })

            }
        }).catch((err) => {
            console.log(err);
            setMySpinner(false);
        })



    }


    return (
        <>


            <ToastContainer />

            <div>
                {/* <!--Site Footer Start--> */}
                <footer class="site-footer">
                    <div class="site-footer__shape-1 float-bob-x">
                        <img src={`${api_url}/assets/staticimg/site-footer-shape-1.png`} alt="static-img" />
                    </div>
                    <div class="site-footer__top">
                        <div class="container">
                            <div class="site-footer__inner">
                                <div class="site-footer__contact-info">
                                    <ul class="site-footer__contact-points list-unstyled">
                                        <li>
                                            <div class="icon">
                                                <span class="icon-location"></span>
                                            </div>
                                            <div class="content">
                                                <h4>Address:</h4>
                                                <p>{`${address}`}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <span class="icon-email"></span>
                                            </div>
                                            <div class="content">
                                                <h4>Email us :</h4>
                                                <a href={`mailto:${email}`}>{email}</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <span class="icon-telephone"></span>
                                            </div>
                                            <div class="content">
                                                <h4>Call us on :</h4>
                                                <a href={`tel:${phone}`}>{phone}</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="site-footer__middle">
                        <div class="container">
                            <div class="row">
                                {/* <div class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                            <div class=" footer-widget__column footer-widget__img-box">
                                <div class="footer-widget__img">
                                    <img src={Footer1} alt=""/>
                                </div>
                            </div>
                        </div> */}
                                <div class="col-xl-2 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                                    <div class="footer-widget__column footer-widget__useful-links1">
                                        <div class="footer-widget__title-box">
                                            <h3 class="footer-widget__title">Useful Links</h3>
                                        </div>
                                        <ul class="footer-widget__useful-links-list1 list-unstyled">
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/about">About Us</Link></li>
                                            <li><Link to="/contact">Contact Us</Link></li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-xl-2 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                                    <div class="footer-widget__column footer-widget__useful-links">
                                        <div class="footer-widget__title-box">
                                            <h3 class="footer-widget__title">Service Areas</h3>
                                        </div>
                                        <ul class="footer-widget__useful-links-list list-unstyled">
                                            <li><Link to="/">Melbourne</Link></li>
                                            <li><Link to="/">Dandenong</Link></li>
                                            <li><Link to="/">Knox</Link></li>
                                            <li><Link to="/">Bayswater</Link></li>
                                            <li><Link to="/">Rowville</Link></li>

                                        </ul>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                                    <div class="footer-widget__column footer-widget__services">
                                        <div class="footer-widget__title-box">
                                            <h3 class="footer-widget__title">Services</h3>
                                        </div>
                                        <ul className="footer-widget__useful-links-list list-unstyled">
                                            {therapiesMenu?.map((data) => {
                                                return (
                                                    <li className="nav-item">
                                                        <Link to={`/service/${data.name.replace(/\s/g, '').toLowerCase()}/${data.id}`} className="nav-link">{data.name}</Link>
                                                    </li>
                                                )
                                            })}


                                        </ul>



                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                                    <div class="footer-widget__column footer-widget__about">
                                        <div class="footer-widget__logo">
                                            <Link to="/"><img src={`${api_url}/assets/logo/${footerlogo}`} alt="logo" width="300" /></Link>
                                        </div>
                                        <div class="site-footer__social">
                                            <Link to={`${fb}`}><i class="fab fa-facebook-f"><FaFacebookF /></i></Link>
                                            <Link to={`${insta}`}><i class="fab fa-linkedin-in"><FaLinkedinIn /></i></Link>
                                            <Link to={`${twtr}`}><i class="fab fa-twitter"><FaTwitter /></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="site-footer__bottom">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="site-footer__bottom-inner">
                                        <p class="site-footer__bottom-text">© Copyright <Link to="/">Ken’s Power House</Link> 2023 . All
                                            right reserved. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="search-popup">
                        <div class="search-popup__overlay search-toggler"></div>


                        <div class="search-popup__content">
                            <form action="#">
                                <label for="search" class="sr-only">search here</label>
                                <input type="text" id="search" placeholder="Search Here..." />
                                <button type="submit" aria-label="search submit" class="thm-btn">
                                    <i class="fa fa-search"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                </footer>


                {/* <!--Site Footer End--> */}
            </div>
            <Button
                // variants={fixedBtn}
                // initial="hidden"
                // animate="show"
                onClick={handleShow}
                // viewport={{ once: false, amount: 0.7 }}
                style={{ borderRadius: '5.745744096650192vw', transition: 'auto' }}
                className='appointment_btn QUICK_QUOTE' >
                <AiFillMessage />
                {/* <BiMessageDetail /> */}
            </Button>
            {/* </OverlayTrigger> */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Book Appointment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='contact_div'>
                        <Form id="contactForm" onSubmit={handleSubmit(onSubmit)} className='row' autoComplete='off'>
                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter name" value={name}
                                    {...register('name', {
                                        required: true, maxLength: 30,
                                        onChange: (e) => setName(e.target.value),
                                    })}
                                />
                                {errors.name && errors.name.type === "required" && <span className='help-block with-errors text-danger'>Enter Name</span>}
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="number" placeholder="Enter Phone Number" maxLength={13}
                                    {...register('phone', {
                                        required: true,
                                        maxLength: 15,
                                        minLength: 10,
                                        required: "Enter Phone Number",
                                        onChange: (e) => setPhone1(e.target.value),
                                    })}
                                    value={phone1}
                                />
                                {errors.phone && errors.phone.type === "required" && <span className='help-block with-errors text-danger'>Enter Phone Number</span>}
                            </Form.Group>
                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter email"
                                    {...register('email', {
                                        required: true, maxLength: 30,
                                        onChange: (e) => setEmail1(e.target.value),
                                    })}
                                />
                                {errors.email && errors.email.type === "required" && <span className='help-block with-errors text-danger'>Enter email Address</span>}
                            </Form.Group>

                            <Form.Group className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <Form.Label>Service</Form.Label>
                                <Form.Select
                                    value={product}
                                    {...register("product", {
                                        onChange: (e) => setProduct(e.target.value),
                                        required: 'Please select service',
                                        pattern: {
                                            message: "Please select service"
                                        }
                                    })}

                                >
                                    <option value="">Choose Service</option>
                                    {allProducts?.map((product) => {
                                        return (

                                            <option key={product.id} value={product.name}>{product.name}</option>


                                            // <ProductLists product={product.products} category={product.name} />
                                        )
                                    })}
                                </Form.Select>

                                {errors.product && <span className='help-block with-errors text-danger'>{errors.product.message}</span>}

                            </Form.Group>
                            <div className='appointment_btn_yellow'>
                                <Button className='m-auto'
                                    type='submit'
                                ><span>Send Enquiry</span></Button>
                            </div>
                            {/* <img src={SendImg} style={{ width: '25px' }} /> */}

                        </Form>
                    </div>
                </Modal.Body>

            </Modal>
            <a href={`mailto:${email}`}>
                <button style={{ borderRadius: '5.745744096650192vw', transition: 'auto' }}
                    className='mail_btn' >
                    <MdEmail />
                    {/* <BiMessageDetail /> */}
                </button>
            </a>
            <a href={`tel:${phone}`}>
                <button style={{ borderRadius: '5.745744096650192vw', transition: 'auto' }}
                    className='scrollTop show_btn' >
                    <FaPhoneAlt />
                    {/* <BiMessageDetail /> */}
                </button>
            </a>
            <a href={`mailto: ${email}`}>
                <button style={{ borderRadius: '5.745744096650192vw', transition: 'auto' }}
                    className='mail_btn' >
                    <MdEmail />
                    {/* <BiMessageDetail /> */}
                </button>
            </a>
            <a href={`tel: ${phone}`}>
                <button style={{ borderRadius: '5.745744096650192vw', transition: 'auto' }}
                    className='scrollTop show_btn' >
                    <FaPhoneAlt />
                    {/* <BiMessageDetail /> */}
                </button>
            </a>
        </>
    )
}

export default Footer
