import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa'
import axios from 'axios';
import { BiLoaderCircle } from 'react-icons/bi';
import { Button } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import dateFormat from 'dateformat';

const RelatedBlogs = ({blogs, title}) => {

  const api_url = process.env.REACT_APP_API_URL;


  console.log(blogs)
  return (

    <>
      <section className="blog-one pt-0">
                <div className="container">
                    <div className="section-title text-center">
                        <span className="section-title__tagline">Related Blogs</span>
                        <h2 className="section-title__title">Recent News & Articles</h2>
                    </div>
                    <div className="row">
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={10}
                            freeMode={true}

                            className="mySwiper"
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                  },
                                640: {
                                  slidesPerView: 3,
                                  spaceBetween: 10,
                                },
                                768: {
                                  slidesPerView:3,
                                  spaceBetween: 10,
                                },
                                1024: {
                                  slidesPerView: 3,
                                  spaceBetween: 10,
                                },
                              }}
                        >
                           {blogs?.map((blogs) => {
              return (
                            <SwiperSlide>

                                <div className="wow fadeInUp" data-wow-delay="100ms">
                                    <div className="blog-one__single">
                                    <a href={`/blogdetails/${blogs.title.replace(/\s/g, '').toLowerCase()}/${blogs.id}`}>
                                        <div className="blog-one__img-box">
                                            <div className="blog-one__img">
                                            <img src={`${api_url}/assets/blog/${blogs.image}`}  alt="blog" />
                                            </div>
                                        </div>
                                         </a>
                                        <div className="blog-one__content-box">
                                            <div className="blog-one__date">
                                            <p><span>Admin</span>{dateFormat(`${blogs.created_at}`, "fullDate")}</p> 
                                            </div>
                                            <h3 className="blog-page__title"><a href={`/blogdetails/${blogs.title.replace(/\s/g, '').toLowerCase()}/${blogs.id}`}>{blogs.title}</a></h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </SwiperSlide>
              )} ) }
                       </Swiper>

                    </div>
                  
                </div>
   
            </section>
   
    </>
    )
}

export default RelatedBlogs