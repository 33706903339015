import React from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const HandleRouting = () => {

    const navigate = useNavigate();

    const shouldRedirect = true;

    useEffect(() => {

        let targetRoute = '';

        const currentUrl = window.location.href;

        if (currentUrl.includes('/residential-service')) {
            targetRoute = '/service/residentialservice/1'
        }

        if (currentUrl.includes('/commercial-service')) {
            targetRoute = '/service/commercialservice/2'
        }

        if (currentUrl.includes('/commercial')) {
            targetRoute = '/service/commercialservice/2'
        }

        if (currentUrl.includes('/industrial-service')) {
            targetRoute = '/service/industrialservice/3'
        }


        if (currentUrl.includes('/industrial-electrician-vs-commercial-electrician')) {
            targetRoute = '/blogdetails/industrialelectricianvs.commercialelectrician/1'
        }

        if (currentUrl.includes('/the-importance-of-electrical-safety-audit')) {
            targetRoute = '/blogdetails/theimportanceofelectricalsafetyaudit/2'
        }

        if (currentUrl.includes('/everything-you-must-know-about-plc-programmable-controllers')) {
            targetRoute = '/blogdetails/everythingyoumustknowaboutplcprogrammablecontrollers/3'
        }

        if (currentUrl.includes('/plc-programmable-logic-controllers')) {
            targetRoute = '/service/plcprogrammablelogiccontrollers/9'
        }

        if (currentUrl.includes('/light-curtains')) {
            targetRoute = '/service/lightcurtains/10'
        }

        if (currentUrl.includes('/vsd-variable-speed-drives')) {
            targetRoute = '/service/vsdvariablespeeddrives/11'
        }

        if (currentUrl.includes('/electrical-safety-audit')) {
            targetRoute = '/service/electricalsafetyaudit/12'
        }
        // 
        navigate(targetRoute);

    }, [navigate, shouldRedirect]);

    return (
        <div>HandleRouting</div>
    )
}

export default HandleRouting