import React from 'react';
import ReactPaginate from 'react-paginate';
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa';

const Pagination = ({ productPerPage, totalProducts, paginate, curPage }) => {


    const handlePageClick = (event) => {
        paginate(event.selected + 1);
    };

    let nPage = Math.ceil(totalProducts / productPerPage);
    let nnPage = Math.floor((totalProducts + productPerPage - 1) / productPerPage);



    return (
        <>
            <ReactPaginate
                previousLabel={<FaAngleLeft  className={curPage === 1 ? "disabledIcon" : "enabledIcon"} />}
                nextLabel={<FaAngleRight className={curPage === nPage ? "disabledIcon" : "enabledIcon"} />}
                breakLabel={"..."}
                pageCount={nnPage}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                onPageChange={handlePageClick}
                containerClassName={"pg-pagination list-unstyled"}
                pageClassName={curPage === 0 ? "count active" : "count"}
                pageLinkClassName={"page-link"}
                previousClassName={curPage === 0 ? "count disabled" : "count"}
                previousLinkClassName={"page-link"}
                nextClassName={curPage === nPage ? "count disabled" : "count"}
                nextLinkClassName={"page-link"}
                breakClassName={"count"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
            />
        </>
    )
}

export default Pagination