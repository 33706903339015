import React from 'react'

const PreLoader = () => {
    return (
        <>
            <div className="loader-wrap">
                <div className="preloader">
                    <div id="handle-preloader" className="handle-preloader">
                        <div className="layer layer-one"><span className="overlay"></span></div>
                        <div className="layer layer-two"><span className="overlay"></span></div>
                        <div className="layer layer-three"><span className="overlay"></span></div>
                        <div className="animation-preloader">
                            <div className="spinner"></div>
                            <div className="txt-loading">
                                <span data-text-preloader="k" className="letters-loading">
                                    K
                                </span>
                                <span data-text-preloader="e" className="letters-loading">
                                    e
                                </span>
                                <span data-text-preloader="n's" className="letters-loading">
                                    n's
                                </span>
                                <span data-text-preloader="p" className="letters-loading">
                                    P
                                </span>
                                <span data-text-preloader="o" className="letters-loading">
                                    o
                                </span>
                                <span data-text-preloader="w" className="letters-loading">
                                    w
                                </span>
                                <span data-text-preloader="e" className="letters-loading">
                                    e
                                </span>
                                <span data-text-preloader="r" className="letters-loading">
                                    r
                                </span>
                                <span data-text-preloader="h" className="letters-loading">
                                    H
                                </span>
                                <span data-text-preloader="o" className="letters-loading">
                                    o
                                </span>
                                <span data-text-preloader="u" className="letters-loading">
                                    u
                                </span>
                                <span data-text-preloader="s" className="letters-loading">
                                    s
                                </span>
                                <span data-text-preloader="e" className="letters-loading">
                                    e
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PreLoader