import React, { useState } from 'react';

import './App.css'
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Pages/Home';
import About from './components/Pages/About';
import Blog from './components/Pages/Blog';
import Contact from './components/Pages/Contact';
import Service from './components/Pages/Service';
import Blogdetails from './components/Pages/Blogdetails';
import { Routes, Route } from 'react-router-dom';
import AnimatedCursor from "react-animated-cursor"
import HandleRouting from './components/common/HandleRouting/HandleRouting';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from './components/common/NotFound';
function App() {

  return (
    <>

      <React.Fragment>
        <Header />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/blog' element={<Blog />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/service/:name/:id' element={<Service />} />
          <Route exact path='/blogdetails/:name/:id' element={<Blogdetails />} />

          <Route exact path='/residential-service' element={<HandleRouting />} />
          <Route exact path='/commercial-service' element={<HandleRouting />} />
          <Route exact path='/commercial' element={<HandleRouting />} />
          <Route exact path='/industrial-service' element={<HandleRouting />} />

          <Route exact path='/industrial-electrician-vs-commercial-electrician' element={<HandleRouting />} />
          <Route exact path='/the-importance-of-electrical-safety-audit' element={<HandleRouting />} />
          <Route exact path='/everything-you-must-know-about-plc-programmable-controllers' element={<HandleRouting />} />

          <Route exact path='/plc-programmable-logic-controllers' element={<HandleRouting />} />
          <Route exact path='/light-curtains' element={<HandleRouting />} />
          <Route exact path='/vsd-variable-speed-drives' element={<HandleRouting />} />
          <Route exact path='/electrical-safety-audit' element={<HandleRouting />} />

          {/* commercial-service */}
          <Route path='/*' element={<NotFound />} />

        </Routes>


        <Footer />
      </React.Fragment >

      <AnimatedCursor
        innerSize={10}
        outerSize={30}
        innerScale={1}
        outerScale={1}
        outerAlpha={0}
        outerStyle={{
          border: '3px solid #CE9B4E',
          zIndex: 9999999
        }}
        innerStyle={{
          backgroundColor: '#CE9B4E',
          zIndex: 9999999

        }}

        clickables={[

          'label[for]',


        ]}
      />


    </>
  )
}

export default App
