import { GrAchievement, GrAscend, GrMapLocation } from "react-icons/gr";
import React, { useState, useEffect } from 'react';
import { FaShippingFast, FaCheckDouble, FaUserTie, FaUserShield, FaMedal, FaStar, FaTelegramPlane, FaMapMarkedAlt, FaRegStar, FaPhoneAlt, FaArrowRight, FaRegThumbsUp, FaHome, FaBuilding, FaRegBuilding } from "react-icons/fa";
import { Ri24HoursFill } from 'react-icons/ri';
import { IoIosCalculator } from 'react-icons/io'
import { GiPriceTag } from 'react-icons/gi';
import PreLoader from '../common/PreLoader';
import axios from 'axios';
import HTMLRenderer from 'react-html-renderer';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { seoData } from "../../data";

const About = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [mySpinner, setMySpinner] = useState(false);
    const [about, setAbout] = useState([]);
    const [why, setWhy] = useState([]);
    const [banner, setBanner] = useState('');
    const [bannertitle, setBannertitle] = useState([]);

    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const [featureImage, setFeatureImage] = useState('');


    const getAllData = () => {

        axios.get(`${api_url}/getBanner/2`).then((response) => {
            console.log(response.data.data);
            setBanner(response.data.data[0].banner_image)
            setBannertitle(response.data.data[0].banner_title)
        }).catch((err) => {
            console.log(err)
        });

        axios.get(`${api_url}/getseo/2`).then((response) => {
            setSeoContent(response.data.data);
            console.log(response.data.data);
        }).catch((err) => {
            console.log(err);
        })


        axios.get(`${api_url}/getaboutUs`).then((response) => {
            console.log(response.data.data)
            setAbout(response.data.data);
        }).catch((err) => {
            console.log(err)
        });

        axios.get(`${api_url}/getwhyChoose`).then((response) => {
            console.log(response.data.data)
            setWhy(response.data.data);
        }).catch((err) => {
            console.log(err)
        });










    }



    useEffect(() => {
        getAllData();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);

    return (


        <>
            <Helmet>
                <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle ? seoTitle : seoData.about.title}</title>
                <meta name="description" content={seoDes ? seoDes : seoData.about.description} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle ? seoTitle : seoData.about.title} />
                <meta property="og:description" content={seoDes ? seoDes : seoData.about.description} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle ? seoTitle : seoData.about.title} />
                <meta name="twitter:description" content={seoDes ? seoDes : seoData.about.description} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>
            {mySpinner && <PreLoader />}

            <section class="page-header">
                <div class="page-header-bg" style={{ backgroundImage: `url(${api_url}/assets/banner/${banner}` }}>
                </div>
                <div class="container">
                    <div class="page-header__inner">
                        <div class="page-header__shape-1 float-bob-y">
                            <img src={`${api_url}/assets/staticimg/page-header-shape-1.png`} alt="static-img" />
                        </div>
                        <h1>{bannertitle}</h1>
                        <ul class="thm-breadcrumb list-unstyled">
                            <li><Link to="">Home</Link></li>
                            <li><span>/</span></li>
                            <li>About Us</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section class="about-three">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-7 col-lg-7">
                            <div class="about-three__left">
                                <div class="section-title text-left">
                                    <span class="section-title__tagline">The Company</span>
                                    <h2 class="section-title__title">About Ken’s Power House Electrics</h2>
                                </div>
                                <HTMLRenderer html={about[0]?.description}
                                />
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-5">
                            <div class="about-three__right">
                                <div class="about-three__img">
                                    <img src={`${api_url}/assets/cms/${about[0]?.image}`} alt="about" class="float-bob-y" />
                                    <div class="about-three__experience">
                                        <h3>18</h3>
                                        <p>Years of experience<br /> in this field</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--Why Choose One Start--> */}
            <section class="why-choose-one">
                <div class="container">
                    <div class="why-choose-one__top">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6">
                                <div class="why-choose-one__left">
                                    <div class="why-choose-one__img-box">
                                        <div class="why-choose-one__img">
                                            <img src={`${api_url}/assets/cms/${why[0]?.image}`} alt="why-choose" />
                                        </div>
                                        <div class="why-choose-one__count-box">
                                            <div class="icon">
                                                <span class="icon-reviews"></span>
                                            </div>
                                            <div class="why-choose-one__content-box">
                                                <h3 class="odometer" data-count="2700">00</h3><span
                                                    class="why-choose-one__letter">+</span>
                                                <p class="why-choose-one__count-text">Satisfied
                                                    <br />Customers</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6">
                                <div class="why-choose-one__right">
                                    <div class="section-title text-left">
                                        <span class="section-title__tagline">Our Professionals</span>
                                        <h2 class="section-title__title">{why[0]?.title}</h2>
                                    </div>
                                    <HTMLRenderer html={why[0]?.description}
                                    />
                                    <ul class="why-choose-one__points list-unstyled">
                                        <li>
                                            <div class="icon"><FaArrowRight />
                                                <span class="fa fa-arrow-right"></span>
                                            </div>
                                            <div class="text">
                                                <p>24/7 Emergency Services</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon"><FaArrowRight />
                                                <span class="fa fa-arrow-right"></span>
                                            </div>
                                            <div class="text">
                                                <p>Free Estimates</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon"><FaArrowRight />
                                                <span class="fa fa-arrow-right"></span>
                                            </div>
                                            <div class="text">
                                                <p>Low Price Guarantee</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon"><FaArrowRight />
                                                <span class="fa fa-arrow-right"></span>
                                            </div>
                                            <div class="text">
                                                <p>Licensed & Insured Experts</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon"><FaArrowRight />
                                                <span class="fa fa-arrow-right"></span>
                                            </div>
                                            <div class="text">
                                                <p>Fast & Reliable Response Times</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="why-choose-one__bottom">
                        <div class="advantage_section">
                            <div class="advantage_heading">
                                <h2 class="text-center">Our <b>Advantages</b></h2>
                                <p class="font24 text-center">Ken’s Power House is your single source for a complete range of high-quality electrical services, including design/build, engineering, construction, start-up, commissioning, operation, and maintenance.</p>

                            </div>
                        </div>
                        <div class="row">
                            {/* <!--Why Choose One Single Start--> */}
                            <div class="col-xs-6 col-sm-4 col-md-3 col-5">
                                <div class="why-choose-one__single">
                                    <div class="icon">
                                        <span class="advantage_icon"><Ri24HoursFill /></span>
                                    </div>
                                    <div class="why-choose-one__content">
                                        <h3 class="why-choose-one__title"><Link to="/"> 24/7 Emergency Services</Link></h3>
                                        {/* <p class="why-choose-one__text-2">There are many variations passages <br /> of Lorem
                                                Ipsum
                                                available, but the majority have suffered alter.</p> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!--Why Choose One Single End--> */}
                            {/* <!--Why Choose One Single Start--> */}
                            <div class="col-xs-6 col-sm-4 col-md-3 col-5">
                                <div class="why-choose-one__single">
                                    <div class="icon">
                                        <span class="advantage_icon"><IoIosCalculator /></span>
                                    </div>
                                    <div class="why-choose-one__content">
                                        <h3 class="why-choose-one__title"><Link to="">Free Estimates</Link></h3>
                                        {/* <p class="why-choose-one__text-2">There are many variations passages <br /> of Lorem
                                                Ipsum
                                                available, but the majority have suffered alter.</p> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!--Why Choose One Single End--> */}
                            {/* <!--Why Choose One Single Start--> */}
                            <div class="col-xs-6 col-sm-4 col-md-3 col-5">
                                <div class="why-choose-one__single">
                                    <div class="icon">
                                        <span class="advantage_icon"><GiPriceTag /></span>
                                    </div>
                                    <div class="why-choose-one__content">
                                        <h3 class="why-choose-one__title"><Link to="">Low Price Guarantee</Link></h3>
                                        {/* <p class="why-choose-one__text-2">There are many variations passages <br /> of Lorem
                                                Ipsum
                                                available, but the majority have suffered alter.</p> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!--Why Choose One Single End--> */}
                            {/* <!--Why Choose One Single Start--> */}
                            <div class="col-xs-6 col-sm-4 col-md-3 col-5">
                                <div class="why-choose-one__single">
                                    <div class="icon">
                                        <span class="advantage_icon"><FaUserTie /></span>
                                    </div>
                                    <div class="why-choose-one__content">
                                        <h3 class="why-choose-one__title"><Link to="">Licensed & Insured Experts</Link></h3>
                                        {/* <p class="why-choose-one__text-2">There are many variations passages <br /> of Lorem
                                                Ipsum
                                                available, but the majority have suffered alter.</p> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!--Why Choose One Single End--> */}
                            {/* <!--Why Choose One Single Start--> */}
                            <div class="col-xs-6 col-sm-4 col-md-3 col-5">
                                <div class="why-choose-one__single">
                                    <div class="icon">
                                        <span class="advantage_icon"><FaShippingFast /></span>
                                    </div>
                                    <div class="why-choose-one__content">
                                        <h3 class="why-choose-one__title"><Link to="">Fast & Reliable Response Times</Link></h3>
                                        {/* <p class="why-choose-one__text-2">There are many variations passages <br /> of Lorem
                                                Ipsum
                                                available, but the majority have suffered alter.</p> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!--Why Choose One Single End--> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Why Choose One End--> */}

        </>
    )
}

export default About
