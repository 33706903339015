export const seoData = {
    home: {
        title: "Top Electric Services & Light Installation in Melbourne | Ken's Electrics",
        description: "Expert electric contractors in Melbourne for light installation, electrical maintenance, and machine repairs. Quick, reliable service. Click to learn more!"
    },
    about: {
        title: "About us - Ken's Power House Electrics",
        description: "Ken's Power House offers top-quality electrical services, including design/build, engineering, construction, and maintenance. we're your trusted partner for all your electrical needs."
    },
    blog: {
        title: "The Latest in Electrical Solutions - Ken's Power House Electrics Blog",
        description: "Stay informed with Ken's Power House Electrics blog for the latest updates, tips, and trends in electrical solutions. Explore now!"
    },
    contactUs: {
        title: "Contact us - Ken's Power House Electrics",
        description: "Get in touch with Ken's Power House Electrics for all your electrical needs. Call us at 0411 632 264 or email ken@kenspowerhouseelectrics.com.au."
    },
    services: [
        {
            id: 1,
            title: "Expert Domestic Electrician Services in Melbourne | Residential Electricals",
            description: "Leading commercial electrician in Melbourne for lighting, wiring, switchboards, and more. Trusted maintenance and emergency services. Discover excellence today!"
        },
        {
            id: 2,
            title: "Commercial Electrician Melbourne: Professional Electrical Services",
            description: "Since 2005, we are offering the best quality seed grading services in Balaklava and Mid North. Experienced seed graders. 100% guaranteed."
        },
        {
            id: 3,
            title: "Industrial Electrician Melbourne: Warehouse & Factory Electrical Experts",
            description: "Specialized industrial electrician services in Melbourne for factories, warehouses, and plants. Wiring, switchboard installs, safety audits. Click for more."
        },
        {
            id: 9,
            title: "PLC Repair & Maintenance Services in Melbourne | Ken's Power House Electrics",
            description: "Expert PLC programmable logic controller services in Dandenong, Bayswater, Rowville, Knox. Reliable repairs, maintenance by skilled technicians. Call now!"
        },
        {
            id: 10,
            title: "Light Curtain Repair & Inspection Services in Dandenong & Bayswater",
            description: "Expert light curtains repair and servicing in Dandenong, Bayswater, Rowville, Knox. Qualified inspectors for all your light curtain needs. Contact us today!"
        },
        {
            id: 11,
            title: "Expert VSD Variable Speed Drives Repair in Melbourne | Ken’s Power House",
            description: "Reliable VSD repair services in Dandenong, Bayswater, Rowville, Knox. Extend your VSD lifespan with our expert maintenance. Quality guaranteed. Call today!"
        },
        {
            id: 12,
            title: "Electrical Safety Audit Services in Melbourne - Ken's Power House Electrics",
            description: "Ken's Power House Electrics offers top-notch electrical safety audit services in Melbourne. Ensure safety compliance with our expert audits."
        }
    ],
    blogDetails: [
        {
            id: 1,
            title: "Industrial Electrician vs. Commercial Electrician - Ken's Power House Electrics",
            description: "The titles “commercial electrician” and “industrial electrician” may sound similar, but they are actually widely different from each other."
        },
        {
            id: 2,
            title: "The Importance of Electrical Safety Audit - Ken's Power House Electrics",
            description: "The sudden fire breakouts and unpredictable accidents at workplaces have become a common threat these days."
        },
        {
            id: 3,
            title: "Everything You Must Know About PLC Programmable Controllers",
            description: "Abbreviated as PLC, Programmable Logic Controllers are solid-state computers known for monitoring the inputs and making logic-based decisions."
        }
    ]
}