import React, { useLayoutEffect, useState, useEffect, useRef, useCallback } from 'react';
import { FaCheckDouble, FaUserTie, FaUserShield, FaMedal, FaStar, FaTelegramPlane, FaMapMarkedAlt, FaRegStar, FaPhoneAlt, FaArrowRight, FaRegThumbsUp, FaHome, FaBuilding, FaRegBuilding } from "react-icons/fa";
import axios from 'axios';
import { Link, NavLink } from 'react-router-dom';

import { FaBars, FaTimes, FaUserCircle } from 'react-icons/fa';




const Header = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [logo, setLogo] = useState('');
    const [phone, setPhone] = useState('');


    const [therapiesMenu, setTherapiesMenu] = useState([]);

    const [click, setClick] = React.useState(false);
    const handleClick = () => setClick(!click);
    const Close = () => setClick(false);

    // sticky header
    const headerStickyRef = useRef();
    let [check, setCheck] = useState(true);
    const sticky = useStickyHeader(50);
    const headerClasses = `main-header ${(sticky && check) ? 'sticky' : ''}`
    const ref = useRef();

    //    main-menu


    const { clientHeight } = ref;

    const checkChange = (value) => {
        setCheck(value);
    };

    const getData = () => {
        axios.get(`${api_url}/getFooter`).then((response) => {
            console.log(response.data);
            setLogo(response.data.data[0].logo)
            setPhone(response.data.data[0].phone)

            setData(response.data.data)
        }).catch((err) => {
            console.log(err);
        });
        axios.get(`${api_url}/getmenuService`).then((response) => {
            console.log(response.data);
            // setLogo(response.data.data[0].logo)
            setTherapiesMenu(response.data.data)
        }).catch((err) => {
            console.log(err);
        })

    }
    useEffect(() => {
        getData();
    }, []);





    function useStickyHeader(offset = 0) {
        const [stick, setStick] = useState(false);

        const handleScroll = () => {
            setStick(window.scrollY > offset);
        };

        useLayoutEffect(() => {
            window.addEventListener('scroll', handleScroll);

            return (() => {
                window.removeEventListener('scroll', handleScroll);
            });
        });

        return stick;
    }
    const [clicked, setClicked] = useState(false);
    const [handleDrop, setHandleDrop] = useState(false);

    const handleClicked = () => {
        setClicked(!clicked);
        setHandleDrop(false)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setHandleDrop(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, [handleDrop]);

    const [submenu, setSubmenu] = useState(false);
    const [SubhandleDrop, setSubHandleDrop] = useState(false);

    const SubhandleClicked = () => {
        setSubmenu(!submenu);
        setSubHandleDrop(false)
    }


    return (
        <>


            <header class={headerClasses} ref={headerStickyRef}>
                <nav class="main-menu">
                    <div class="main-menu__wrapper">
                        <div class="main-menu__wrapper-inner">
                            <div class="main-menu__left">
                                <div class="main-menu__logo">
                                    <Link to="/">
                                        <img src={`${api_url}/assets/logo/${logo}`} alt="logo" width="300" /></Link>
                                </div>
                                <div class="main-menu__main-menu-box">
                                    <div className='menu-icon'>
                                        <span
                                            onClick={handleClicked}
                                        >
                                            {clicked ? <FaTimes className='iconColor' /> : <FaBars className='iconColor' />}
                                        </span>
                                    </div>

                                    <ul className={clicked ? 'main-menu__list showLinks' : 'main-menu__list hideLinks'}>
                                        <li class="dropdown">

                                            <Link end to='/' onClick={() => setClicked(false)}>Home</Link>
                                        </li>
                                        <li>
                                            <Link end to='/about' onClick={() => setClicked(false)}>About Us</Link>
                                        </li>


                                        {<li class="dropdown" >


                                            <span className={'servicename'} onClick={SubhandleClicked}>Services</span>


                                            <ul class={submenu ? "footer-widget__useful-links-list list-unstyled d-block" : "footer-widget__useful-links-list list-unstyled"}>
                                                {therapiesMenu?.map((data) => {
                                                    return (
                                                        <li>
                                                            <Link to={`/service/${data.name.replace(/\s/g, '').toLowerCase()}/${data.id}`} activeclassname="active" className="nav-link" onClick={() => setClicked(false)}>{data.name}</Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>

                                        </li>}




                                        {/* 

                                     {<ul className="dropdown-menu">
                                            {therapiesMenu?.map((data) => {
                                                return (
                                                    <li className="nav-item">
                                                        <Link to={`/service/${data.id}`} activeclassname="active" className="nav-link">{data.name}</Link>
                                                    </li>
                                                )
                                            })}
                                           

                                        </ul> }  */}

                                        <li class="dropdown">



                                            <Link end to='/blog' onClick={() => setClicked(false)}>Blogs</Link>


                                        </li>
                                        <li>
                                            <Link end to='/contact' onClick={() => setClicked(false)}>Contact Us</Link>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="main-menu__right">
                                <div class="main-menu__call-btn-box">
                                    <div class="main-menu__call">
                                        <div class="main-menu__call-icon">
                                            <span class="icon-telephone"></span>
                                        </div>
                                        <div class="main-menu__call-number">
                                            <p><a href={`tel:${phone}`}><FaPhoneAlt style={{ marginRight: '8px' }} />{`${phone}`}</a></p>


                                        </div>
                                    </div>
                                    <div class="main-menu__btn-box">
                                        <Link to="/contact" class="thm-btn main-menu__btn">Request a    Free Quote</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>


            <div class="stricky-header stricked-menu main-menu">
                <div class="sticky-header__content"></div>
            </div>



        </>

    )


}

export default Header