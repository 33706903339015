
import { FaAngleRight } from 'react-icons/fa'
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FaCheckDouble, FaUserTie, FaWarehouse, FaTelegramPlane, FaMapMarkedAlt, FaRegStar, FaPhoneAlt, FaArrowRight, FaRegThumbsUp, FaHome, FaBuilding, FaRegBuilding } from "react-icons/fa";
import PreLoader from '../common/PreLoader';
import Pagination from '../common/Pagination';
import axios from 'axios';
import dateFormat from 'dateformat';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { seoData } from '../../data';


const Blog = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [blogs, setBlogs] = useState([]);
    const [banner, setBanner] = useState('');
    const [bannertitle, setBannertitle] = useState([]);

    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const [featureImage, setFeatureImage] = useState('');

    const [heroData, setHeroData] = useState([]);
    const [bannerdescription, setBannerdescription] = useState([]);
    const [dataPerPage, setDataPerPage] = useState(6);
    const [pending, setPending] = useState(false)

    const getData = () => {

        axios.get(`${api_url}/getBanner/3`).then((response) => {
            console.log(response.data.data);
            setBanner(response.data.data[0].banner_image)
            setBannertitle(response.data.data[0].banner_title)
            setBannerdescription(response.data.data[0].banner_description)

            setHeroData(response.data.data)
        }).catch((err) => {
            console.log(err)
        });

        axios.get(`${api_url}/getseo/3`).then((response) => {
            setSeoContent(response.data.data);
            console.log(response.data.data);
        }).catch((err) => {
            console.log(err);
        })


        axios.get(`${api_url}/getcardBlog`).then((response) => {
            console.log(response.data.data)
            setBlogs(response.data.data);
            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true);
        });

    };

    useEffect(() => {
        getData();
    }, []);

    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);



    const [mySpinner, setMySpinner] = useState(false);

    const [curPage, setCurPage] = useState(1);
    const [perPage, setPerPage] = useState(4);
    const indexOfLast = curPage * perPage;
    const indexOfFirst = indexOfLast - perPage;
    const curBlogs = blogs?.slice(indexOfFirst, indexOfLast);

    const ref = useRef(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const paginate = useCallback((pageNumber) => {
        setCurPage(pageNumber);
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }, [perPage],)

    return (
        <>
            <Helmet>
                <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle ? seoTitle : seoData.blog.title}</title>
                <meta name="description" content={seoDes ? seoDes : seoData.blog.description} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle ? seoTitle : seoData.blog.title} />
                <meta property="og:description" content={seoDes ? seoDes : seoData.blog.description} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle ? seoTitle : seoData.blog.title} />
                <meta name="twitter:description" content={seoDes ? seoDes : seoData.blog.description} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>
            {mySpinner && <PreLoader />}

            <section class="page-header">
                <div class="page-header-bg" style={{ backgroundImage: `url(${api_url}/assets/banner/${banner}` }}>
                </div>
                <div class="container">
                    <div class="page-header__inner">
                        <div class="page-header__shape-1 float-bob-y">
                            <img src={`${api_url}/assets/staticimg/page-header-shape-1.png`} alt="static-img" />
                        </div>
                        <h1>{bannertitle}</h1>
                        <ul class="thm-breadcrumb list-unstyled">
                            <li><Link to="">Home</Link></li>
                            <li><span>/</span></li>
                            <li>Blog</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section class="blog-grid" ref={ref}>
                <div class="container">
                    <div class="row">
                        {curBlogs?.map((data) => {
                            return (
                                <div class="col-xl-6 col-lg-6 col-md-6">
                                    <div class="blog-page__single">
                                        <Link to={`/blogdetails/${data.title.replace(/\s/g, '').toLowerCase()}/${data.id}`}>
                                            <div class="blog-page__img">
                                                <img src={`${api_url}/assets/blog/${data.image}`} alt="blog" />
                                                {/* <div class="blog-page__date">
                                                    <p>25</p>
                                                    <span>June</span>
                                                </div> */}
                                            </div>
                                        </Link>
                                        <div class="blog-page__content">
                                            <ul class="list-unstyled blog-page__meta">
                                                <div class="blog-one__date">
                                                    <p><span>Admin</span>{dateFormat(`${data.created_at}`, "fullDate")}</p>

                                                </div>                                            </ul>
                                            <h3 class="blog-page__title"><Link to={`/blogdetails/${data.title.replace(/\s/g, '').toLowerCase()}/${data.id}`}>{data.title}</Link></h3>
                                            <p class="blog-page__text">{data.description}</p>
                                            <div class="blog-page__btn-box">
                                                <Link to={`/blogdetails/${data.title.replace(/\s/g, '').toLowerCase()}/${data.id}`} class="thm-btn blog-page__btn">read more</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}





                    </div>
                    <div class="row">
                        <div class="col-xl-12">

                            <div class="blog-page__pagination">
                                <Pagination productPerPage={perPage} totalProducts={blogs?.length} paginate={paginate} curPage={curPage} />
                                {/* <ul class="pg-pagination list-unstyled">
                                    <li class="count"><Link href="#">1</Link></li>
                                    <li class="count"><Link href="#">2</Link></li>
                                    <li class="next">
                                        <Link href="#" aria-label="Next"><i class="fa fa-angle-right"><FaAngleRight /></i></Link>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Blog
