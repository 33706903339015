
import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper/modules";
import PreLoader from '../common/PreLoader';
import axios from 'axios'
import { Link, useParams } from 'react-router-dom';
import HTMLRenderer from 'react-html-renderer';
import RelatedBlogs from '../common/RelatedBlogs';
import { Helmet } from "react-helmet";
import { seoData } from '../../data';

const Blogdetails = () => {

    const api_url = process.env.REACT_APP_API_URL;


    const { id } = useParams();

    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const [featureImage, setFeatureImage] = useState('');

    const [blogs, setBlogs] = useState([]);
    const [heroData, setHeroData] = useState([]);
    const [banner, setBanner] = useState('');
    const [allBlogs, setAllBlogs] = useState([]);
    const [pending, setPending] = useState(false)

    const getData = () => {


        axios.get(`${api_url}/getBanner/3`).then((response) => {
            console.log(response.data.data);
            setBanner(response.data.data[0].banner_image)
            setHeroData(response.data.data)
        }).catch((err) => {
            console.log(err)
        });

        axios.get(`${api_url}/getsingleBlog/${id}`).then((response) => {
            console.log(response.data.data)
            setSeoContent(response.data.data);
            setBlogs(response.data.data);

            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });

        axios.get(`${api_url}/getcardBlog`).then((response) => {
            console.log(response.data.data)
            setAllBlogs(response.data.data);
            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });

    };

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();
    }, [id])



    const [mySpinner, setMySpinner] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);

    let defaultSeoData = seoData.blogDetails.filter(item => item.id == id);

    return (
        <>
            <Helmet>
                <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle ? seoTitle : defaultSeoData[0]?.title}</title>
                <meta name="description" content={seoDes ? seoDes : defaultSeoData[0]?.description} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle ? seoTitle : defaultSeoData[0]?.title} />
                <meta property="og:description" content={seoDes ? seoDes : defaultSeoData[0]?.description} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle ? seoTitle : defaultSeoData[0]?.title} />
                <meta name="twitter:description" content={seoDes ? seoDes : defaultSeoData[0]?.description} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>
            {mySpinner && <PreLoader />}

            <section class="page-header">
                <div class="page-header-bg" style={{ backgroundImage: `url(${api_url}/assets/banner/${banner}` }}>
                </div>
                <div class="container">
                    <div class="page-header__inner">
                        <div class="page-header__shape-1 float-bob-y">
                            <img src={`${api_url}/assets/staticimg/page-header-shape-1.png`} alt="static-img" />
                        </div>
                        <h1>{blogs[0]?.title}</h1>
                        <ul class="thm-breadcrumb list-unstyled">
                            <li><Link href="/">Home</Link></li>
                            <li><span>/</span></li>
                            <li><Link href="/blog">Blog</Link></li>
                            <li><span>/</span></li>
                            <li>{blogs[0]?.title}</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section class="electrical-panels">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6">
                            <div class="electrical-panels__left">
                                <div class="electrical-panels__img-box">
                                    <div class="electrical-panels__img-one">
                                        <img src={`${api_url}/assets/blog/${blogs[0]?.image}`} alt="blog-img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="electrical-panels__right">
                                <div class="section-title text-left">
                                    <span class="section-title__tagline">blog details</span>
                                    <h2 class="section-title__title">{blogs[0]?.title}</h2>
                                </div>
                                <HTMLRenderer html={blogs[0]?.short_description} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="secondsection">
                <div class="container">
                    <HTMLRenderer html={blogs[0]?.description} />

                </div>
            </section>




            <RelatedBlogs blogs={allBlogs?.filter(data => data.id != id)} title={"Related Blogs"} />


        </>
    )
}

export default Blogdetails